<template>
  <div id="users">
    <h1>Users</h1>
    <b-table
      v-if="users.length > 0"
      striped
      hover
      :fields="fields"
      :items="users"
    >
    </b-table>
    <b-alert v-else class="mt-5" show>Currently There's no Users</b-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.getUsers();
  },
  data() {
    return {
      fields: ["id", "email", "username"],
    };
  },
  methods: {
    ...mapActions({
      getUsers: "getUsers",
    }),
    // editEvent(id) {
    //   this.$router.push({ name: "Edit Event", params: { id } });
    // },
  },
  computed: {
    ...mapGetters({
      users: "users",
      // currentUser: "currentUser",
      // roles: "roles",
    }),
  },
};
</script>

<style lang="scss" scoped>
#users {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
